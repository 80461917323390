export const container = "c19gcapb";
export const heading = "h1rv5rj0";
export const about = "a1yxitoa";
export const nameLink = "ngwv4kf";
export const name = "ns3jmzq";
export const description = "dytm9ye";
export const tagList = "t7f8o0s";
export const tag = "t1tvnceg";
export const avatar = "an9dof9";
export const button = "blm6iiw";

require("./styles.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");