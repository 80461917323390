export const container = "c19xtpi";
export const heading = "hza0xtn";
export const creatorPhoto = "clq9j86";
export const tvLogos = "t1cbz9ss";
export const mainContent = "m1dns06s";
export const linkButton = "lpt3lzg";
export const linkButtonWhite = "l7rs370";
export const mainLogos = "moba0yd";
export const mainText = "m8rpmf0";
export const mainDescription = "mn13ns1";
export const mainBottomButtons = "midck1u";
export const actionButton = "a18lj3th";
export const mainScrollDown = "m1c03xi1";
export const secondHeader = "s1twt556";
export const secondDescription = "ssvvvjz";
export const footer = "f1ag832o";

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");